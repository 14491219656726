import React, { Fragment } from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import ArticlesComponent from "../components/articles"

import messages_en from '../translations/tr.json';

const IndexPage = ({location}) => {
  
  return (
  <Layout location={location} i18nMessages={messages_en}>
    <StaticQuery
      query={graphql`
        query {
          allStrapiArticle(limit: 10, sort: {fields: published_at, order: DESC}) {
            edges {
              node {
                id
                slug
                title
                category {
                  name
                }
                image {
                  childImageSharp {
                    fluid(maxWidth: 780, quality: 80, toFormat: JPG) {
                      aspectRatio
                      src
                      srcSet
                      sizes
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
          <Fragment>
            <h2 className="uk-text-center uk-text-left@l">Neruia</h2>
            <ArticlesComponent langKey="en" articles={data.allStrapiArticle.edges} />
          </Fragment>
      )}
    />
  </Layout>
)}

export default IndexPage

IndexPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};